import React from "react"
import { getCourseNavigation } from "../../../store/courses"
import LayoutCourse from "../../../layouts/course"

import Box from "../../../components/box"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Paragraph from "../../../components/paragraph"
import CourseFooterNext from "../../../components/course-footer-next"
import Heading from "../../../components/heading"
import Note from "../../../components/note"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import TextLink from "../../../components/textlink"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Kurs starten"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen"
        />
      }
      navigation={navigation}
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Informationen für Lehrer*innen
          </Heading>
          <Paragraph dropcap={true}>
            In diesem Kurs beschäftigen sich die Schüler*innen mit der Form des
            Gedichts. Sie lernen Merkmale, die das Gedicht auszeichnen und von
            anderen Textformen unterscheiden (
            <TextLink to="/kurse/versteckte-ordnung/01-gedicht-erkennen">
              Kapitel 1
            </TextLink>
            ) und erfahren, dass Gedichte häufig nach einem bestimmten Prinzip
            geordnet sind: nach Reimen und Klangwiederholungen, Versen und
            Strophen, einer Aufzählung oder einer konkreten Figur (
            <TextLink to="/kurse/versteckte-ordnung/02-ordnung">
              Kapitel 2
            </TextLink>
            ).
          </Paragraph>
          <Note title="Hinweis">
            Der Kurs kann sowohl von den Schüler*innen eigenständig bearbeitet
            als auch in den Unterricht integriert werden. Alle Einheiten sind
            interaktiv angelegt. Sie fördern eine produktive und kreative
            Erschließung literarischer Formen und Texte, sensibilisieren die
            Schüler*innen für die Machart literarischer Texte und tragen zur
            ästhetischen Bildung bei.
          </Note>
          <Paragraph>
            Die Kapitel sind an folgenden Inhalten des Bildungsplans angelehnt:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>Zuordnung von Textformen und Textsorten</Bullet>
            <Bullet>
              Literarische Texte verstehen und in ihrer ästhetischen Qualität
              erfassen
            </Bullet>
          </BulletList>
          <Paragraph>
            Die von den Schüler*innen eigenständig erarbeiteten Inhalte können
            in Form eines Unterrichtsgesprächs noch einmal aufgegriffen und
            abgefragt werden. Mögliche Impulse wären beispielsweise:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>An welchen Merkmalen erkennt man ein Gedicht?</Bullet>
            <Bullet>Ist ein Gedicht immer gereimt?</Bullet>
            <Bullet>
              Wie kann ein Gedicht nach einer Figur geordnet sein?
            </Bullet>
            <Bullet>Welche anderen Ordnungsmuster gibt es für Gedichte?</Bullet>
          </BulletList>
          <Paragraph>
            Möglichkeiten für eine praktische Unterrichtsfortführung:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>Ein eigenes Figurengedicht erstellen</Bullet>
            <Bullet>
              Ein Gedicht aus lauter Wörtern mit dem gleichen Vokal verfassen –
              so wie es Ernst Jandl in seinem Gedicht ›Ottos Mops‹ gemacht hat
            </Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
